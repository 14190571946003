import React, { Component } from "react"
import PropTypes from "prop-types"
import { sendFeedback, formatPayload } from "./FeedbackFormAPI"
// import PreviewInputFile from "./PreviewInput"

import css from "@css/components/common/form.module.styl"

// The SuccessMessage replaces the form if the API succesfully returns
const SuccessMessage = () => <p className={css.success}>お問い合わせありがとうございました。</p>;

// The ErrorMessage is displayed at the top of the form if API returns error
const ErrorMessage = () => (
	<p className={css.error}>送信中にエラーが発生しました。</p>
);

class FeedbackForm extends Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			nameValue: '',
			nameError: '',
			emailValue: "",
			emailError: "",
			fileValue: "",
			telValue: "",
			telError: "",
			subjectValue: "",
			subjectError: "",
			feedbackValue: "",
			feedbackError: "",
			submitError: false,
			isLoading: false,
			isSubmitted: false
		};
		this.handleSubjectChange = this.handleSubjectChange.bind(this)
		this.handleTelChange = this.handleTelChange.bind(this)
		this.handleNameChange = this.handleNameChange.bind(this)
		this.handleEmailChange = this.handleEmailChange.bind(this)
		this.handleFeedbackChange = this.handleFeedbackChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	handleNameChange(event) {
		this.setState({ nameValue: event.target.value });
	}

	handleEmailChange(event) {
		this.setState({ emailValue: event.target.value })
	}

	handleTelChange(event) {
		this.setState({ telValue: event.target.value })
	}

	handleSubjectChange(event) {
		this.setState({ subjectValue: event.target.value });
	}

	handleFeedbackChange(event) {
		this.setState({ feedbackValue: event.target.value });
	}

	handleUploadChange = (data,image) =>{
		this.setState({ uploadsValue: data, uploadsImagesValue: image})
	}

	async handleSubmit(event) {
		event.preventDefault();

		let errorList = {
			feedbackError: "",
			emailError: "",
			telError: "",
			subjectError: "",
			nameError: "",
			submitError: false
		}

		let count = 0
		// Validate the email field
		// Email regex pattern - if an ivalid email is entered, Zendesk API will return and error
		const regex = /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-\/=?\^_`{|}~a-zA-Z0-9]{0,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{2,63}){1,}))$/; // eslint-disable-line
		if ( this.state.emailValue.length === 0 || !regex.test(this.state.emailValue) ) {
			errorList.emailError = "正しいメールアドレスを入力してください"
			count += 1
		}

		// Validate the feedback field
		if (this.state.feedbackValue.length === 0) {
			errorList.feedbackError = "お問い合わせ内容を入力してください"
			count += 1
		}

		if (this.state.nameValue.length === 0) {
			errorList.nameError = "お名前を入力してください"
			count += 1
		}

		// var tel = document.getElementById('tel').value.replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi,'')
		// if (!tel.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/) && this.state.telValue.length === 0) {
		// 	this.setState({ telError: "正しい電話番号を入力してください" });
		// 	count += 1
		// }
		var tel = document.getElementById('tel').value.replace(/[━.*‐.*―.*－.*\-.*ー.*\-]/gi,'')
		if (!tel.match(/^(0[5-9]0[0-9]{8}|0[1-9][1-9][0-9]{7})$/) && this.state.telValue.length !== 0　) {
			errorList.telError = "正しい電話番号を入力してください"
			count += 1
		}

		if (this.state.subjectValue.length === 0) {
			errorList.subjectError = "件名を入力してください"
			count += 1
		}

		if(
			errorList.feedbackError !== "" ||
			errorList.emailError !== "" ||
			errorList.nameError !== "" ||
			errorList.telError !== "" ||
			errorList.subjectError !== "" ||
			count > 0
		){
			this.setState({
				feedbackError: errorList.feedbackError,
				emailError: errorList.emailError,
				telError: errorList.telError,
				subjectError: errorList.subjectError,
				nameError: errorList.nameError,
				submitError: errorList.submitError
			})
			return
		}

		// If fields are valid, set state to loading and make API POST request
		this.setState({ isLoading: true });
		const payload = formatPayload(
			{
				name: this.state.nameValue,
				email: this.state.emailValue,
				tel: this.state.telValue,
				subject: this.state.subjectValue,
				feedback: this.state.feedbackValue,
				currentPageURL: this.props.currentPageURL(),
			}
		)
		console.log(payload)
		const response = await sendFeedback(payload, this.props.subdomain);

		// If we dont get a success response, set submitError to true to show error message
		if (!response.request) {
			this.setState({ isLoading: false, submitError: true });
			return;
		}

		// If we get a success response, set isSubmitted to true to show the success message
		this.setState({ isLoading: false, isSubmitted: true });
	}

	render() {
		if (this.state.isSubmitted) {
			return (
				<div role="alert" aria-live="polite" aria-atomic="true">
					<SuccessMessage />
				</div>
			);
		} else {
			return (
				<div className={css.container}>
					<p className={css.text}>
						<a href="https://sugarcorp.zendesk.com/hc/ja">SUGARサポートページ</a>に該当の回答がないかどうかご確認ください。<br/>
						ご確認後も解決しない場合、以下フォームよりお問い合わせください。ご回答までには、2～3営業日いただいております。<br/>ご了承ください。
					</p>
					<form onSubmit={this.handleSubmit} encType="multipart/form-data">
						<div className={css.section}>
							<div className={css.inner}>
								<div className={css.label}>
									<label htmlFor="name">お名前*</label>
								</div>
								<div className={css.content}>
									<input
										id="name"
										type="text"
										name="name"
										placeholder="お名前を入力"
										autoComplete="nope"
										value={this.state.nameValue}
										onChange={this.handleNameChange}
									/>
								</div>
							</div>
							<p className={css.error}>{this.state.nameError}</p>
						</div>
						<div className={css.section}>
							<div className={css.inner}>
								<div className={css.label}>
									<label htmlFor="email">メールアドレス*</label>
								</div>
								<div className={css.content}>
									<input
										id="email"
										type="text"
										name="email"
										autoComplete="nope"
										placeholder="メールアドレスを入力"
										value={this.state.emailValue}
										onChange={this.handleEmailChange}
									/>
								</div>
							</div>
							<p className={css.error}>{this.state.emailError}</p>
						</div>
						<div className={css.section}>
							<div className={css.inner}>
								<div className={css.label}>
									<label htmlFor="tel">電話番号</label>
								</div>
								<div className={css.content}>
									<input
										id="tel"
										type="tel"
										name="tel"
										autoComplete="nope"
										placeholder="電話番号を入力"
										value={this.state.telValue}
										onChange={this.handleTelChange}
									/>
								</div>
							</div>
							<p className={css.error}>{this.state.telError}</p>
						</div>
						<div className={css.section}>
							<div className={css.inner}>
								<div className={css.label}>
									<label htmlFor="subject">件名*</label>
								</div>
								<div className={css.content}>
									<input
										id="subject"
										type="text"
										name="subject"
										autoComplete="nope"
										placeholder="件名を入力"
										value={this.state.subjectValue}
										onChange={this.handleSubjectChange}
									/>
								</div>
							</div>
							<p className={css.error}>{this.state.subjectError}</p>
						</div>
						<div className={css.section}>
							<div className={css.inner}>
								<div className={css.label}>
									<label htmlFor="feedback">お問い合わせ内容*</label>
								</div>
								<div className={css.content}>
									<textarea
										id="feedback"
										name="feedback"
										rows="8"
										autoComplete="nope"
										placeholder="お問い合わせ内容を入力"
										value={this.state.feedbackValue}
										onChange={this.handleFeedbackChange}
										error={this.state.feedbackError}
									/>
								</div>
							</div>
							<p className={css.error}>{this.state.feedbackError}</p>
						</div>
						{/*<div className={css.section}>
							<div className={css.inner}>
								<div className={css.label}>
									<label htmlFor="file">ファイルの添付</label>
								</div>
								<div className={css.content}>
									<PreviewInputFile handleUploadChange={this.handleUploadChange}/>
								</div>
							</div>
							<p className={css.error}>{this.state.telError}</p>
						</div>*/}
						<div className={css.section}>
							<div className={css.alert} role="alert" aria-live="polite" aria-atomic="true">
								{this.state.submitError && <ErrorMessage />}
							</div>
							<button className={css.submitButton} type="submit">
								{this.state.isLoading ? "送信中..." : "送信"}
							</button>
						</div>
					</form>
				</div>
			);
		}
	}
}

FeedbackForm.defaultProps = {
	feedbackSubject: "Website Feedback",
	defaultUserName: "Anonymous user"
};

FeedbackForm.propTypes = {
	subdomain: PropTypes.string,
	feedbackSubject: PropTypes.string,
	defaultUserName: PropTypes.string,
	currentPageURL: PropTypes.func
};

export default FeedbackForm;