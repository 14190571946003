import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

import Layout from "@layouts/index"
import SEO from "@components/seo"

import css from "@css/components/common/page.module.styl"
import formCss from "@css/components/common/form.module.styl"

import Footer from '@components/Footer'
import Zendesk from '@components/zendesk/FeedbackForm'

//sugar
// const ZENDESK_KEY = 'oUrY3rRtu9iveJhQYTRcnxwFmQhjlGvz7Ynlb9wf'
const ZENDESK_DOMAIN = 'sugarcorp'
// const ZENDESK_DOMAIN = 'kaboheihelp'

class Contact extends React.PureComponent {
    render(){
        const { location, data } = this.props
        const {
            contact__img__76JVdzLW
        } = data
        return(
            <div>
                <SEO title="CONTACT" />
                <main>
                    <div className={css.container}>
                        <section className={css.section}>
                            <div className={css.header}>
                                <p className={css.label}>CONTACT</p>
                            </div>
                            <div className={formCss.container}>
                                <p className={formCss.text}>
                                    <a href="https://sugarcorp.zendesk.com/hc/ja">SUGARサポートページ</a>に該当の回答がないかどうかご確認ください。<br/>
                                    ご確認後も解決しない場合、以下のメールアドレス宛にお問い合わせください。ご回答までには、2～3営業日いただいております。<br/>ご了承ください。
                                </p>
                            </div>
                                
                                <div style={{maxWidth:300}}>
                                    <Image fluid={contact__img__76JVdzLW.childImageSharp.fluid}/>
                                </div>


                            

                            {/*<Zendesk
                                subdomain={ZENDESK_DOMAIN}
                                currentPageURL={()=>{
                                    if( typeof window ){
                                        return window.location
                                    }
                                }}
                            />*/ }
                        </section>
                    </div>
                </main>
                <Footer/>
            </div>
        )
    }
}


export default Contact

export const query = graphql`
    query {

        contact__img__76JVdzLW : file(relativePath: { eq: "contact__img__76JVdzLW.png" }) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
