// export const sendUploads = async (filename, data) => {
// 	try {
// 		const headers = {
// 			"Content-Type": "application/binary"
// 		};
// 		const response = await fetch(
// 			`${process.env.GATSBY_ZD_API_URL}/uploads.json?filename=${encodeURI(filename)}`,
// 			// `https://${subdomain}.zendesk.com/api/v2/uploads.json?filename=${filename}`,
// 			{
// 				method: "POST",
// 				body: JSON.stringify(data),
// 				headers
// 			}
// 		);
// 		const responseData = await response.json();
// 		return responseData;
// 	} catch (error) {
// 		return error;
// 	}
// }

/**
 * POST feedback data to Zendesk
 * https://developer.zendesk.com/rest_api/docs/core/requests#create-request
 *
 * @param {Object} data      - Feedback data payload
 * @param {String} subdomain - Subdomain of zendesk account eg https://${subdomain}.zendesk.com
 *
 * @return {Object}          - Response from Zendesk
 *
 */
export const sendFeedback = async (data, subdomain) => {
	try {
		const headers = {
			"Content-Type": "application/json"
		};
		const response = await fetch(
			`https://${subdomain}.zendesk.com/api/v2/requests.json`,
			{
				method: "POST",
				body: JSON.stringify(data),
				headers
			}
		);
		const responseData = await response.json();

		return responseData;
	} catch (error) {
		return error;
	}
};

/**
 * Format a payload of form data to the format required by Zendesk
 *
 * @param {String} subject  - Zendesk ticket subject
 * @param {String} email    - Email provided by user giving feedback
 * @param {String} feedback - Feedback provided by user
 * @param {String} pageURL  - URL of page user submitted feedback from
 *
 * @return {Object}         - Payload for Zendesk Requests API
 *
 */
 export const formatPayload = ({
 		name,
 		email,
 		subject,
 		feedback,
 		tel,
 		currentPageURL
 }) => {
	const requester = email
		? { name: name, email }
		: { name: name };

	const comment = currentPageURL
		? {
			body: `
				【お名前】
				　${name}
				------------------------------------------
				【メールアドレス】
				  ${email}
				------------------------------------------
				【電話番号】
				　${tel}
				------------------------------------------
				【件名】
				　${subject}
				------------------------------------------
				【お問い合わせ内容】
				　${feedback}
				------------------------------------------
				WebのContactから送信されています。(${currentPageURL})
			`
		}
		:
		{ body: feedback };

	return {
		request: {
			requester,
			subject,
			comment,
		}
	};
};